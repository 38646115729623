import React, { useEffect } from "react";
import styled from "styled-components";

// Utils
import { Font, rem, responsive } from "../../utils/style";
import { getIncentiveDemographicFromSKU } from "../../utils/product";
import { trackProductViewed } from "../../utils/tracking/product";

const ProductSelectorWrapper = styled.div`
  margin: 0 auto 32px;
  display: flex;

  ${responsive.sm`
    margin: 0 0 32px;
    max-width: calc(100% - 20px);
  `};

  ${responsive.md`
    margin: 0 0 40px;
  `};
`;

const ProductButton = styled.button`
  ${Font.circular}
  border-radius: 8px;
  background: #fff;
  border: ${props =>
    props.active
      ? "2px solid rgb(20, 43, 111)"
      : "2px solid rgba(20, 43, 111, 0.56)"};
  color: ${props =>
    props.active ? "rgb(20, 43, 111)" : "rgba(20, 43, 111, 0.56)"};

  height: 40px;
  width: 100%;

  transition: 250ms;
  font-size: ${rem(14)};
  font-weight: 500;
  letter-spacing: 0px;

  ${responsive.sm`
    width: auto;
    padding: 0 24px;
  `}

  ${responsive.md`
    height: 42px;
    font-size: ${rem(16)};
  `}

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &:not(:last-of-type) {
    margin-right: 16px;
  }
`;

export default function ProductSelector({
  products,
  plans,
  selectedProduct,
  setSelectedProduct,
  setSelectedPlan,
}) {
  useEffect(() => {
    const index = products.findIndex(product => product === selectedProduct);
    trackProductViewed(plans[selectedProduct.sku], { position: index + 1 });
  }, []);

  return (
    <ProductSelectorWrapper>
      {products &&
        products.map((product, index) => {
          const age = getIncentiveDemographicFromSKU(product.sku);

          return (
            <ProductButton
              key={product.sku}
              active={product.sku === selectedProduct.sku}
              onClick={() => {
                const plan = plans[product.sku];
                trackProductViewed(plan, index + 1);
                setSelectedProduct(product);
                setSelectedPlan(plan);
              }}
            >
              {age}
            </ProductButton>
          );
        })}
    </ProductSelectorWrapper>
  );
}
