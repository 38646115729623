import React from "react";
import styled from "styled-components";

// Services
import intl from "../../services/intl";

// Utils
import { responsive } from "../../utils/style";

// Redux
import { connect } from "react-redux";
import userSelectors from "../../store/user/selectors";

// Actions
import { addProductToCart } from "../../store/cart/actions";
import { updateRedirects } from "../../store/redirects/actions";

// Components
import DiscountButton from "./DiscountButton";

const ButtonWrapper = styled.div`
  width: 100%;

  ${responsive.md`
    width: auto;
  `};
`;

const Button = styled(DiscountButton)`
  width: 100%;

  ${responsive.sm`
    width: auto;
    padding-left: 37px;
    padding-right: 37px;
  `};

  ${responsive.md`
    padding-left: 26px;
    padding-right: 26px;
  `};
`;

export const AddToBundleButtonComponent = class AddToBundleButton extends React.Component {
  handleClick() {
    const {
      dispatchUpdateRedirects,
      dispatchAddProductToCart,
      plan,
      isLoggedIn,
      propertiesToTrack,
    } = this.props;

    if (!isLoggedIn) {
      // Set redirect location to /cart after user logs in
      dispatchUpdateRedirects({
        login: "/cart",
      });
    }

    dispatchAddProductToCart(plan.id, propertiesToTrack);
  }

  render() {
    const { isLoggedIn, plan } = this.props;
    const toLocation = isLoggedIn ? "/cart" : "/login";

    const price = plan.amount / 100;

    return (
      <ButtonWrapper>
        <Button
          to={toLocation}
          onClick={this.handleClick.bind(this)}
          prefix={intl.t("general.discount-button-prefix", "Add")}
          price={price}
        />
      </ButtonWrapper>
    );
  }
};

const mapStateToProps = state => {
  return {
    isLoggedIn: userSelectors.isLoggedIn(state),
  };
};

export default connect(mapStateToProps, {
  dispatchUpdateRedirects: updateRedirects,
  dispatchAddProductToCart: addProductToCart,
})(AddToBundleButtonComponent);
