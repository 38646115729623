import React from "react";
import styled from "styled-components";

// Utils
import { Color, Font, responsive } from "../../utils/style";
import { Icons } from "../../utils/svg";

// Styled Components
const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;

  ${responsive.sm`
    width: calc(100vw - 48px);
  `}

  ${responsive.lg`
    width: calc(100vw - 80px);
    max-width: 1920px;
  `}
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(242, 241, 245);
`;

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${responsive.sm`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  `};
`;

const Title = styled.h1`
  ${Font.circular};
  color: ${Color.ritualBlue};
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 24px;

  ${responsive.md`
    letter-spacing: -0.4px;
    line-height: 36px;
    font-size: 30px;
    margin-bottom: 32px;
  `};
`;

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 40px 0;

  ${responsive.md`
    padding: 56px 0;
  `};
`;

const BusterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  max-width: 280px;
  padding-bottom: 32px;

  :last-child {
    padding-bottom: 0px;
  }

  svg {
    width: 32px;
    height: 32px;
  }

  ${responsive.sm`
    width: 200px;
    padding-bottom: 0;
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  `};

  ${responsive.md`
    width: 240px;
    margin-right: 70px;

    svg {
      width: 40px;
      height: 40px;
    }
  `};

  ${responsive.lg`
    width: 310px;
    margin-right: 56px;
  `};
`;

const BusterTitle = styled.h2`
  ${Font.circular};
  color: ${Color.ritualBlue};
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 26px;
  text-align: center;
  margin-top: 6px;

  ${responsive.sm`
    margin-top: 8px;
  `};

  ${responsive.md`
    font-size: 20px;
    margin: 16px 0;
    line-height: 30px;
  `};
`;

const BusterText = styled.p`
  ${Font.circular};
  color: rgba(20, 43, 111, 0.56);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 20px;
  text-align: center;
  margin-bottom: 0;

  ${responsive.md`
    font-size: 16px;
    line-height: 22px;
  `};
`;

function Buster({ title, icon, body }) {
  let Icon = Icons[icon];
  return (
    <BusterDiv>
      {Icon && <Icon />}
      <BusterTitle>{title}</BusterTitle>
      <BusterText>{body}</BusterText>
    </BusterDiv>
  );
}

export default function BarrierBusters({ barrierBusters }) {
  if (!barrierBusters) return null;
  return (
    <Wrapper>
      <StyledRow>
        <ContentArea>
          <Title>{barrierBusters.title}</Title>
          <Grid>
            {barrierBusters.items &&
              barrierBusters.items.map((key, index) => {
                return (
                  <Buster
                    key={index}
                    title={key.title}
                    icon={key.icon}
                    body={key.body}
                  />
                );
              })}
          </Grid>
        </ContentArea>
      </StyledRow>
    </Wrapper>
  );
}
