import React from "react";

// Components
import AsyncRitualButton from "../global/AsyncRitualButton";
import DiscountButtonCopy from "./DiscountButtonCopy";

// Utils
import { variation } from "../../utils/launchDarkly";

export default class DiscountButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newYearsPromoEnabled: false,
    };
  }

  componentDidMount() {
    const newYearsPromoEnabled = variation("new-years-promo");

    this.setState({
      newYearsPromoEnabled,
    });
  }

  render() {
    let { className, showStrikethrough, prefix, price } = this.props;
    const { newYearsPromoEnabled } = this.state;
    // include long text formatting for strikethrough button when new years promo
    // is enabled (ie $23.70)
    if (newYearsPromoEnabled && showStrikethrough) {
      className = className || "";
      className += " long-text";
    }
    return (
      <AsyncRitualButton {...this.props} className={className}>
        <DiscountButtonCopy prefix={prefix} price={price} showStrikethrough={showStrikethrough} />
      </AsyncRitualButton>
    );
  }
}
