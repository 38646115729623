import React from "react";
import styled, { css } from "styled-components";

// Utils
import { Font, capitalize, responsive } from "../../utils/style";
import { ModalTitle, ModalDescriptionTopMargin } from "./index";
import { Icons } from "../../utils/svg";
import NameMap from "../../utils/nameMap";

// Components
import AddToBundleButton from "./AddToBundleButton";
import MagicModal from "../MagicModal";

// Styled Elements
const modalStyle = css`
  padding: 24px 20px;

  ${responsive.sm`
    height: 685px;
    width: 700px;
    padding: 56px 0 0;
    display: flex;
    flex-direction: column;
    position: relative;
  `};

  ${responsive.md`
    height: 683px;
    width: 774px;
  `};

  ${responsive.lg`
    height: 683px;
    width: 770px;
  `};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 24px;

  ${responsive.sm`
    padding-right: 80px;
    padding-left: 80px;
  `}
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  ${responsive.sm`
    flex-direction: row;
  `}
  ${props =>
    props.buttonrow &&
    css`
      ${responsive.sm`
        margin-top: 43px;
        justify-content: center;
      `}
    `}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${responsive.sm`
   width: 45%;
   margin-right: 40px;
  `}
`;

const Divider = styled.div`
  background: rgba(0, 0, 0, 0.16);
  height: 1px;
  margin: 24px 0;
  margin-top: ${props => props.first && "32px"};
  ${responsive.sm`
    margin-top: ${props => props.first && "24px"};
  `}
`;

const Label = styled.p`
  color: rgba(20, 43, 111, 0.56);
  ${Font.circular}
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const IngredientRow = styled.div`
  display: flex;
  justify-content: ${props => (props.dosage ? "flex-start" : "space-between")};
  align-items: center;
  margin-bottom: ${props => (props.dosage ? "24px" : "8px")};
  :last-child {
    margin-bottom: 0;
  }
`;

const IngredientText = styled.p`
  color: rgb(20, 43, 111);
  ${Font.circular}
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 26px;
  margin: 0;
  ${props =>
    props.secondary &&
    css`
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      text-align: ${props => (props.dosage ? "left" : "right")};
    `}
`;

export default class ProductModal extends React.Component {
  createValuesArray(valueIcons, valueLabels) {
    return valueIcons.map((icon, i) => ({
      icon,
      label: valueLabels[i],
    }));
  }

  render() {
    const {
      isOpen,
      onRequestClose,
      discount,
      selectedProduct,
      selectedPlan,
    } = this.props;

    const ingredients = selectedProduct.supplementFacts.simpleIngredients;
    const { valuePropIcons, valuePropLabels } = selectedProduct;
    const valuesArr = this.createValuesArray(valuePropIcons, valuePropLabels);
    const productName = NameMap(selectedProduct.name);

    return (
      <MagicModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentStyle={modalStyle}
        mobileFixedClose={true}
        mobileFullPage={true}
      >
        <Container>
          <ModalTitle dangerouslySetInnerHTML={{ __html: productName.html }} />
          <ModalDescriptionTopMargin>
            {selectedProduct.headerDescription}
          </ModalDescriptionTopMargin>

          <div className="d-block d-sm-none">
            <AddToBundleButton
              plan={selectedPlan}
              discount={discount}
              propertiesToTrack={{
                location: "Modal",
                promotion_id: "bundle",
              }}
            />
          </div>

          <Divider first />
          <Row>
            <Column>
              <Label>{`${ingredients &&
                ingredients.length} Ingredients`}</Label>
              {ingredients &&
                ingredients.map((key, index) => {
                  return (
                    <IngredientRow key={index}>
                      <IngredientText>{key.name}</IngredientText>
                      <IngredientText
                        secondary
                      >{`${key.dosage}`}</IngredientText>
                    </IngredientRow>
                  );
                })}
            </Column>
            <Divider />
            <Column>
              <Label>Traceability</Label>
              {valuesArr &&
                valuesArr.map((key, index) => {
                  let cleanedIconName = key.icon
                    .replace("icon", "")
                    .replace(/-/gi, "");
                  let IconElement = Icons[capitalize(cleanedIconName)];
                  return (
                    <IngredientRow key={index} dosage={true}>
                      <IconElement
                        style={{ width: 32, height: 32, marginRight: 8 }}
                      />
                      <IngredientText
                        secondary={true}
                        dosage={true}
                      >{`${key.label}`}</IngredientText>
                    </IngredientRow>
                  );
                })}
            </Column>
          </Row>
          <Row buttonrow>
            <div className="d-none d-sm-block">
              <AddToBundleButton
                plan={selectedPlan}
                discount={discount}
                propertiesToTrack={{
                  location: "Modal",
                  promotion_id: "bundle",
                }}
              />
            </div>
          </Row>
        </Container>
      </MagicModal>
    );
  }
}
