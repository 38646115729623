import React from "react";

// Components
import StrikeText from "../global/StrikeText";
import Currency from "../Currency";

// Utils
import { getDiscountData } from "../../utils/bundle";

export default function DiscountButtonCopy({
  prefix,
  price,
  showStrikethrough = true,
}) {
  const { discountPrice } = getDiscountData(price);

  if (price === discountPrice || !showStrikethrough) {
    return (
      <span>
        {prefix} &#8212; <Currency value={price} round={true} />
      </span>
    );
  }

  return (
    <>
      {prefix} &#8212; ${discountPrice}{" "}
      <StrikeText>
        <Currency value={price} round={true} />
      </StrikeText>
    </>
  );
}
