import React from "react";
import { graphql } from "gatsby";

// Components
import PageSEO from "../components/seo/Page";
import BundleHero from "../components/bundle/BundleHero";
import AlternativeShop from "../components/bundle/AlternativeShop";
import BarrierBusters from "../components/bundle/BarrierBusters";
import PillSection from "../components/product/PillSection";

export default class BundlingIncentivePage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Add Product Incentive Page",
    });

    this.props.updateLayoutState({
      navColor: "#FFFFFF",
      banner: true,
    });
  }

  render() {
    const { plans } = this.props.pageContext;
    const {
      slug,
      title,
      pageTitle,
      pageDescription,
      products,
      discount,
      showFormatSection,
      barrierBusters,
      alternativeShopLandingPage,
      alternativeShopLandingLinkCopy,
      alternativeShopLandingUrl,
    } = this.props.data.contentfulBundlingIncentivePage;

    const alternativeShopLandingCopy = {
      alternativeShopLandingPage,
      alternativeShopLandingLinkCopy,
      alternativeShopLandingUrl,
    };

    // TODO: Bubble up the selected product for the hero and use that product
    // to determine which pill section to show.
    const pillSectionTitle = products[0].pillSectionTitle;
    const pillSectionImage = products[0].pillSectionImage;
    const pillSectionAccordion = products[0].pillSectionAccordion;

    const seo = {
      pagePath: slug,
      title: pageTitle,
      description: pageDescription,
    };

    return (
      <>
        <PageSEO {...seo} noIndex={true} />
        <BundleHero
          plans={plans}
          title={title}
          products={products}
          discountInitial={discount}
        />
        {showFormatSection ? (
          <PillSection
            className="mb-0"
            pillSectionTitle={pillSectionTitle}
            pillSectionImage={pillSectionImage}
            pillSectionAccordion={pillSectionAccordion}
          />
        ) : (
          <BarrierBusters barrierBusters={barrierBusters} />
        )}
        <AlternativeShop
          alternativeShopLandingCopy={alternativeShopLandingCopy}
        />
      </>
    );
  }
}

export const pageQuery = graphql`
  query BundlingIncentivePageQuery($locale: String!, $slug: String!) {
    contentfulBundlingIncentivePage(
      node_locale: { eq: $locale }
      slug: { eq: $slug }
    ) {
      title
      slug
      subheader
      pageTitle
      pageDescription
      products {
        sku
        name {
          name
          childMarkdownRemark {
            html
          }
        }
        valuePropIcons
        valuePropLabels
        supplementFacts {
          simpleIngredients {
            name
            dosage
          }
        }
        headerDescription
        heroFeaturedImages {
          title
          fluid(maxWidth: 555, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
        pillSectionTitle {
          childMarkdownRemark {
            html
          }
        }
        pillSectionImage {
          description
          fluid(maxWidth: 470, maxHeight: 470, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        pillSectionAccordion {
          title
          content
        }
      }
      showFormatSection
      discount
      barrierBusters {
        title
        items {
          body
          icon
          title
        }
      }
      alternativeShopLandingPage {
        childMarkdownRemark {
          html
        }
      }
      alternativeShopLandingLinkCopy
      alternativeShopLandingUrl
    }
  }
`;
