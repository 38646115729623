import React from "react";
import styled from "styled-components";

const Strike = styled.span`
  opacity: 0.56;
  text-decoration: line-through;
  margin-left: 7px;

  ${p => p.contentStyle};
`;

export default class StrikeText extends React.Component {
  render() {
    const { className, contentStyle } = this.props;
    return (
      <Strike className={className} contentStyle={contentStyle}>
        {this.props.children}
      </Strike>
    );
  }
}
