import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

// Services
import intl from "../../services/intl";

// Redux
import subscriptionSelectors from "../../store/subscription/selectors";

// Components
import DiscountButtonCopy from "./DiscountButtonCopy";
import RitualButton from "../global/RitualButton";

// Utils
import { responsive } from "../../utils/style";
import metrics from "../../utils/metrics";

// Styled Components
const QuickAddButtonWrapper = styled.div`
  width: 100%;

  a {
    width: 100%;
  }

  ${responsive.sm`
    a {
      width: 270px;
    }
  `};

  ${responsive.md`
    width: auto;

    a {
      width: 225px;
    }
  `};
`;

function onClickTrack(title) {
  metrics.track("CTA Clicked", {
    location: "Bundle Incentive",
    pageTitle: title,
  });
}

export default function QuickAddButton({ plan, title }) {
  const allSubscriptions = useSelector(subscriptionSelectors.allSubscriptions);
  const quickAddUrl = `${process.env.GATSBY_ACCOUNT_URL}/subscription/${allSubscriptions[0]?.id}/add/confirm?pid=${plan.id}`;
  const price = plan.amount / 100;

  return (
    <QuickAddButtonWrapper>
      <RitualButton
        onClick={() => onClickTrack(title)}
        href={quickAddUrl}
        target="_blank"
        rel="noreferrer"
        title={`Quick Add ${plan.id}`}
      >
        <DiscountButtonCopy
          prefix={intl.t("general.discount-button-prefix", "Add")}
          price={price}
        />
      </RitualButton>
    </QuickAddButtonWrapper>
  );
}
