import React from "react";
import styled from "styled-components";

// Utils
import { Color, rem, responsive } from "../../utils/style";
import metrics from "../../utils/metrics";

// Components
import Container from "../../components/Container";
import Row from "../../components/Row";
import MagicLink from "../MagicLink";

// Styled Elements
const Wrapper = styled.div`
  width: 100%;
`;

const StyledContainer = styled(Container)`
  padding: 48px 0 56px;

  ${responsive.md`
    padding: 64px 0 81px;
  `};
`;

const Column = styled.div.attrs({
  id: "product-hero_container_row_content",
  className: "col-12",
})`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Content = styled.div`
  width: 280px;

  ${responsive.sm`
    width: 100%;
  `};
`;

const ShopCopy = styled.div`
  p {
    font-size: ${rem(22)};
    line-height: ${rem(32)};
    letter-spacing: -0.2px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  ${responsive.sm`
    display: inline-block;

    p {
      display: inline-block;
      margin: 0 8px 0 0;
    }
  `};

  ${responsive.md`
    p {
      font-size: ${rem(30)};
      line-height: ${rem(36)};
      letter-spacing: -0.4px;
    }
  `};
`;

const ShopLink = styled(MagicLink)`
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  letter-spacing: -0.2px;
  font-weight: 500;
  margin-bottom: 0;
  border-bottom: 2px solid ${Color.ritualBlue};
  transition: opacity 0.2s;

  ${responsive.md`
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    letter-spacing: -0.4px;
  `};

  &:hover {
    opacity: 0.56;
  }
`;

// Helpers
function handleClick(title) {
  metrics.track("CTA Clicked", {
    location: "Add Product Incentive Page",
    title,
    nonInteraction: false,
  });
}

export default function AlternativeShop({ alternativeShopLandingCopy }) {
  if (!alternativeShopLandingCopy) return null;

  const {
    alternativeShopLandingPage,
    alternativeShopLandingLinkCopy,
    alternativeShopLandingUrl,
  } = alternativeShopLandingCopy;

  const { childMarkdownRemark } = alternativeShopLandingPage;

  return (
    <Wrapper>
      <StyledContainer>
        <Row>
          <Column>
            <Content>
              <ShopCopy
                dangerouslySetInnerHTML={{ __html: childMarkdownRemark.html }}
              ></ShopCopy>
              <ShopLink
                onClick={e => handleClick(alternativeShopLandingLinkCopy, e)}
                to={alternativeShopLandingUrl}
                title={alternativeShopLandingLinkCopy}
              >
                {alternativeShopLandingLinkCopy}
              </ShopLink>
            </Content>
          </Column>
        </Row>
      </StyledContainer>
    </Wrapper>
  );
}
