import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Img from "gatsby-image";

// Redux
import userSelectors from "../../store/user/selectors";
import subscriptionSelectors from "../../store/subscription/selectors";

// Components
import Container from "../Container";
import Row from "../Row";
import MagicLink from "../MagicLink";
import Highlighter from "../global/Highlighter";
import Text from "../Text";

import AddToBundleButton from "./AddToBundleButton";
import ProductModal from "./ProductModal";
import ProductSelector from "./ProductSelector";

import QuickAddButton from "./QuickAddButton";

// Icons
import Checkmark from "../../../static/svg/icon-check-yellow.svg";
import BundleImage from "../../../static/svg/icon-bundles-badge.svg";

// Utils
import { Color, Font, media, responsive } from "../../utils/style";
import { getDiscountData } from "../../utils/bundle";
import { getPropertiesForProduct } from "../../utils/tracking/helpers";
import metrics from "../../utils/metrics";
import { variation } from "../../utils/launchDarkly";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 48px 0;
  overflow: hidden;

  ${responsive.sm`
    margin-top: 96px;
 `};

  ${responsive.md`
    margin-bottom: 80px;
  `};
`;

const ContentRow = styled(Row).attrs({
  className: "row",
})`
  ${media.mobile`
    margin: 0 -10px;
  `}

  align-items: center;
`;

const ContentArea = styled.div.attrs({
  id: "product-hero_container_row_content",
  className: "col-12 col-sm-6 col-lg-5",
})`
  order: 1;
  padding-top: 16px;

  ${responsive.sm`
    order: 0;
    padding-top: 0;
    padding-left: 0;
  `};
`;

const AttributeText = styled.p`
  ${Font.circular}
  color: rgb(20, 43, 111);
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 20px;
  margin-bottom: 0;
  margin-left: 16px;
  ${responsive.sm`
    font-weight: 500;
    margin-left: 14px;
  `};
  ${responsive.md`
    font-size: 16px;
  `};
`;

const ImageArea = styled.div.attrs({
  id: "bundle-hero_container_row_image",
  className: "col-12 col-sm-6 offset-lg-1",
})`
  padding-right: 0;

  ${media.tablet`
    padding-left: 10px;
  `}

  ${media.mobile`
    padding: 0 4px;
  `}
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const HeroImage = styled(Img)`
  width: 100%;
  height: 100%;
  height: calc((100vw - 16px) * 0.72368421052);
  max-height: 550px;
  background-color: #ffd600;
  ${responsive.sm`
    max-height: 358px;
    max-width: 340px;
  `}
  ${responsive.md`
    max-height: 478px;
    max-width: 454px;
  `}
  ${responsive.lg`
   max-height: 600px;
   max-width: 570px;
  `}
`;

const Divider = styled.div`
  border: 1px solid rgb(226, 226, 226);
  height: 1px;
  width: 280px;
  margin: 31px auto;
  ${responsive.sm`
    display: none;
  `}
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  width: 100%;
  ${responsive.sm`
    align-items: flex-start;
  `}
  ${responsive.md`
    flex-direction: row;
    align-items: center;
  `}
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  :last-child {
    margin-bottom: 0;
  }
  ${responsive.sm`
    margin-bottom: 0;
  `}
  ${responsive.md`
  margin-bottom: 16px;
  :last-child {
    margin-bottom: 0;
  }
  `}
`;

const StyledAttributesColumn = styled.div`
  ${responsive.sm`
    display: none;
  `}
  ${responsive.md`
   display: block;
   margin-top: 40px;
  `}
`;

const StyledAttributesRow = styled.div`
  display: none;
  ${responsive.sm`
    display: flex;
    justify-content: space-between;
    margin: 48px auto 0;
    width: 100%;
  `}
  ${responsive.md`
    display: none;
  `}
`;

const StyledBundleImage = styled(BundleImage)`
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 100;
  width: 62px;
  height: 62px;

  ${responsive.md`
    width: 118px;
    height: 118px;
    top: 24px;
    left: 24px;
  `}
`;

const Title = styled.h1`
  ${Font.circular};
  color: ${Color.ritualBlue};
  font-size: 26px;
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: 36px;
  margin: 0;

  div span {
    ${Font.circular};
    font-style: normal;
    display: ${props => (props.discount ? "inline" : "none")};
  }

  .italic {
    ${Font.dutch};
    font-style: italic;
  }

  ${responsive.md`
    font-size: 40px;
    width: 454px;
    letter-spacing: -1px;
    line-height: 48px;
  `};
`;

const DiscountText = styled.p`
  ${Font.circular};
  color: rgba(20, 43, 111, 0.56);
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 0;
`;

const DescriptionText = styled.p`
  ${Font.dutch};
  color: ${Color.ritualBlue};
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 26px;
  margin-top: 8px;
  margin-bottom: 32px;

  ${responsive.md`
    font-size: 18px;
    line-height: 28px;
    width: 454px;
    margin-top: 24px;
  `};

  a {
    cursor: pointer;
    border-bottom: solid 1px ${Color.ritualBlue};
  }
`;

const attributes = [
  "Free shipping—always.",
  "Easy cancellation—anytime.",
  "Don’t love it? It’s on us.",
];

const AddToBundleButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;

  ${responsive.sm`
    margin-bottom: 24px;
  `};

  ${responsive.md`
    width: auto;
    margin-right: 24px;
    margin-bottom: 0;
  `}
`;

function Attributes() {
  return (
    <React.Fragment>
      {attributes.map((key, index) => {
        return (
          <StyledRow key={index}>
            <Checkmark />
            <AttributeText>{key}</AttributeText>
          </StyledRow>
        );
      })}
    </React.Fragment>
  );
}

function trackLearnModelOpened(selectedPlan) {
  metrics.track("Overlay Opened", {
    title: "Learn More",
    location: "Add Product Incentive Page - Hero",
    product: getPropertiesForProduct(selectedPlan.id),
  });
}

export default function BundleHero({
  plans,
  title,
  products,
  discountInitial,
}) {
  // Default first product for SSR
  const initialState = products[0];
  // Setup Hooks
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(initialState);
  const [selectedPlan, setSelectedPlan] = useState(plans[initialState.sku]);
  const [discount, setDiscount] = useState(discountInitial);
  const [displayQuickAdd, setDisplayQuickAdd] = useState(false);
  const isLoggedIn = useSelector(userSelectors.isLoggedIn);
  const allSubscriptions = useSelector(subscriptionSelectors.allSubscriptions);

  // ComponentDidMount - We check for query param here
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const pid = params.get("pid");
    const showBundleDiscount = params.get("sbd");

    // If pid query param exists, we find the product associated
    if (pid) {
      const keys = Object.keys(plans);
      keys.forEach(key => {
        const plan = plans[key];
        if (!plan) return;

        if (plan.id === pid) {
          const product = products.find(product => product.sku === key);
          if (product) {
            setSelectedProduct(product);
            setSelectedPlan(plans[product.sku]);
          }
        }
      });
    }

    // If save param is passed as "0", update discount value to 0
    if (showBundleDiscount === "0") {
      setDiscount(0);
    }

    const quickAddEligible = isLoggedIn && allSubscriptions.length === 1;
    if (quickAddEligible) {
      // If user is eligible for bucketing, check variation
      const quickAddFlag = variation("bundle-incentive-quick-add");
      setDisplayQuickAdd(quickAddFlag);
    }
  }, [isLoggedIn, allSubscriptions]);

  const primaryTitle = title.split("*")[0];
  const subTitle = title.split("*")[1];

  const discountData = getDiscountData(selectedPlan.amount / 100);

  return (
    <Wrapper>
      <Container>
        <ContentRow>
          <ContentArea>
            <Title discount={discount}>
              {primaryTitle}<span className="italic">{subTitle}</span>
              <br className="d-none d-sm-block" />
              {discount > 0 && (
                <>
                  <span> and </span>
                  <Highlighter hover={false} percent={25}>
                    <span>{discountData.titleCopy}</span>
                  </Highlighter>
                </>
              )}
            </Title>

            <DescriptionText>
              {selectedProduct.headerDescription}{" "}
              <MagicLink
                onClick={e => {
                  trackLearnModelOpened(selectedPlan, e);
                  setIsModalOpen(true);
                }}
              >
                Learn More
              </MagicLink>
            </DescriptionText>
            {products && products.length > 1 && (
              <ProductSelector
                products={products}
                plans={plans}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                setSelectedPlan={setSelectedPlan}
              />
            )}
            <StyledColumn>
              <AddToBundleButtonWrapper>
                {displayQuickAdd ? (
                  <QuickAddButton title={title} plan={selectedPlan} />
                ) : (
                  <AddToBundleButton
                    plan={selectedPlan}
                    discount={discount}
                    propertiesToTrack={{
                      location: "Hero",
                      promotion_id: "bundle",
                    }}
                  />
                )}
              </AddToBundleButtonWrapper>
              {discount > 0 && (
                <DiscountText>
                  <Text
                    id="bundle.hero.discount"
                    defaultMessage="Save {amount} on your next order"
                    values={{ amount: discountData.sentenceCopy }}
                  />
                </DiscountText>
              )}
            </StyledColumn>
            <Divider />
            <StyledAttributesColumn>
              <Attributes />
            </StyledAttributesColumn>
          </ContentArea>
          <ImageArea>
            {selectedProduct.heroFeaturedImages &&
              selectedProduct.heroFeaturedImages.length > 0 && (
                <ImageWrapper>
                  <StyledBundleImage />
                  <HeroImage
                    loading="eager"
                    fadeIn
                    fluid={selectedProduct.heroFeaturedImages[0].fluid}
                    alt={selectedProduct.headerDescription}
                  />
                </ImageWrapper>
              )}
          </ImageArea>
        </ContentRow>
        <StyledAttributesRow>
          <Attributes />
        </StyledAttributesRow>
      </Container>
      <ProductModal
        isOpen={isModalOpen}
        onRequestClose={setIsModalOpen}
        selectedPlan={selectedPlan}
        selectedProduct={selectedProduct}
        discount={discount}
      />
    </Wrapper>
  );
}
